/* App.css */
.App {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	background: linear-gradient(135deg, #111, #333);
	color: white;
	font-family: 'Roboto', sans-serif;
	overflow: hidden; /* Prevent page scroll */
}

.form {
	display: flex;
	flex-direction: column;
	padding: 2rem;
	border-radius: 10px;
	background-color: #2a2a2a;
	background-image: linear-gradient(to right, #232526, #414345);
	backdrop-filter: blur(10px);
	border: 2px solid #6fcac5;
	width: 90%;
	max-width: 400px;
}

.form button {
	padding: 1rem;
	border-radius: 10px;
	border: none;
	background: #6fcac5;
	color: white;
	cursor: pointer;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	transition: background-color 0.3s;
	font-weight: bold;
	font-size: 1.1em;
}

.form button:hover {
	background: #346e6c;
}

.form button:disabled {
	cursor: not-allowed;
	opacity: 0.5;
}

.errorMessage {
	color: red;
}

h1 {
	font-size: 1.5em;
	font-weight: bold;
}

input {
	margin: 0.5rem 0;
	padding: 1rem;
	border-radius: 10px;
	border: 2px solid transparent;
	background: rgba(255, 255, 255, 0.1);
	color: white;
	outline: none;
	transition: border-color 0.3s ease-in-out;
}

input:focus {
	border-color: #6fcac5;
}

.talk-to-rondah-container {
	display: flex;
	flex-direction: column;
	/* justify-content: space-between; */
	align-items: center;
	padding: 2rem;
	background-color: #2a2a2a;
	background-image: linear-gradient(to right, #232526, #414345);
	border-radius: 10px;
	backdrop-filter: blur(10px);
	border: 2px solid #6fcac5;
	width: 90%; /* Ensures consistent width */
	max-width: 800px; /* Increased max-width for larger screens */
	max-height: 90vh; /* Relative to viewport */
	box-sizing: border-box;
	overflow: hidden; /* Hide any overflow */
}

.talk-to-rondah-container .top-section {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 3rem;
}

.talk-to-rondah-container .action-button {
	background: transparent;
	color: #6fcac5;
	border: 1px solid #6fcac5;
	padding: 0.75rem 1.5rem;
	border-radius: 8px;
	font-weight: 500;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
}

.talk-to-rondah-container .action-button:hover {
	background-color: #6fcac5;
	color: white;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.follow-up-modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.follow-up-modal-content {
	background-color: rgba(255, 255, 255, 0.1);
	border-radius: 20px;
	backdrop-filter: blur(10px);
	border: 1px solid #6fcac5;
	padding: 1.5rem;
	display: flex;
	flex-direction: column;
	width: 50%;
	height: 90%;
	position: relative;
	transition: width 0.3s ease; /* Added transition for width */
}

.follow-up-modal-content p {
	margin-bottom: 1rem;
}

.follow-up-modal-close-button {
	position: absolute;
	top: 1.5rem;
	right: 1.5rem;
	background: none;
	border: none;
	cursor: pointer;
	margin: 0;
	padding: 0;
}

.follow-up-modal-close-button:hover {
	background: transparent;
}

.follow-up-modal-close-button:hover svg {
	color: #888888;
}

.follow-up-calendly-inline-widget {
	height: 100% !important;
}

@media only screen and (min-width: 1400px) {
	.follow-up-modal-content {
		width: 40%;
	}
}

@media only screen and (min-width: 1165px) and (max-width: 1399px) {
	.follow-up-modal-content {
		width: 50%;
	}
}

@media only screen and (min-width: 769px) and (max-width: 1164px) {
	.follow-up-modal-content {
		width: 60%;
	}
}

@media only screen and (min-width: 577px) and (max-width: 768px) {
	.talk-to-rondah-container {
		width: 85%;
		padding: 1.5rem;
	}

	.follow-up-modal-content {
		width: 70%;
	}
}

@media only screen and (max-width: 576px) {
	.talk-to-rondah-container {
		width: 90%;
		padding: 1rem;
		max-height: 90vh; /* Increased max-height for mobile */
	}

	.talk-to-rondah-container .action-buttons {
		flex-direction: column;
		gap: 0.5rem;
	}

	.talk-to-rondah-container .action-button {
		padding: 0.5rem 1rem;
		font-size: 0.9rem;
		width: 100%;
	}

	.follow-up-modal-content {
		width: 90%;
		padding: 1rem;
	}

	.follow-up-modal-close-button {
		top: 1rem;
		right: 1rem;
	}
}
