.disclaimer-section {
	width: 100%;
}

.disclaimer-toggle {
	width: 100%;
	background-color: #2a2a2a;
	color: #6fcac5;
	border: none;
	padding: 1rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	transition: background-color 0.3s, border-radius 0.3s;
	border-radius: 8px; /* Default rounded corners */
}

.disclaimer-toggle.expanded {
	border-radius: 8px 8px 0 0; /* Flatten bottom corners when expanded */
}

.disclaimer-toggle:hover {
	background-color: #414345;
}

.disclaimer-icon {
	margin-right: 0.5rem;
	color: #6fcac5;
}

.disclaimer-title {
	flex-grow: 1;
	text-align: left;
	font-weight: bold;
	font-size: 1.1rem;
}

.disclaimer-chevron {
	margin-left: 0.5rem;
}

.disclaimer-content {
	background-color: #2a2a2a;
	color: white;
	padding: 1rem;
	overflow-y: auto;
	max-height: 35vh;
	opacity: 1;
	border-radius: 0 0 8px 8px; /* Rounded bottom corners by default */
	transition: max-height 0.5s ease, opacity 0.5s ease, border-radius 0.3s ease;
}

.disclaimer-content.collapsed {
	max-height: 0;
	opacity: 0;
	padding: 0 1rem; /* Adjust padding when collapsed */
	border-radius: 8px; /* Fully rounded when collapsed */
}

.disclaimer-content.expanded {
	max-height: 35vh;
	opacity: 1;
	border-radius: 0 0 8px 8px; /* Flatten top corners when expanded */
}

.disclaimer-content p {
	margin-bottom: 1rem;
	line-height: 1.5;
}

.disclaimer-content strong {
	color: #6fcac5;
}

/* Scrollbar Styling (Optional) */
.disclaimer-content::-webkit-scrollbar {
	width: 8px;
}

.disclaimer-content::-webkit-scrollbar-track {
	background: #2a2a2a;
	border-radius: 4px;
}

.disclaimer-content::-webkit-scrollbar-thumb {
	background-color: #6fcac5;
	border-radius: 4px;
}

/* Animation */
@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Responsive Adjustments */
@media (max-width: 768px) {
	.disclaimer-toggle {
		flex-direction: row;
		align-items: center;
		padding: 0.75rem;
	}

	.disclaimer-icon {
		margin-right: 0.3rem;
	}

	.disclaimer-title {
		font-size: 1rem;
	}

	.disclaimer-chevron {
		margin-left: 0.3rem;
	}

	.disclaimer-content {
		padding: 0.75rem;
		max-height: 50vh;
	}

	.disclaimer-content p {
		font-size: 0.9rem;
	}
}
